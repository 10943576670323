<template>
  <div>
    <HomeAppBar />
    <v-container>
      <v-row class="mt-1 white">
        <v-col cols="2">
          <v-btn icon to="/" class="justify-center" color="blue-grey lighten-3">
            <v-icon>fas fa-angle-left</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="8" sm="8" class="d-flex justify-center">
          <h3 class="light-blue--text">Property by category</h3>
        </v-col>
      </v-row>
      <v-row v-if="propertyByTypeStatus === 204">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <v-img src="../assets/House_searching.png" />
          <span class="primary--text title mt-4">Property not available.</span>
          <span class="blue-grey--text">Please change property type. </span>
        </v-col>
      </v-row>
      <v-row v-if="propertyByTypeStatus === 200">
        <DisplayProperty
          v-for="property in propertyByType"
          :key="property.id"
          :property="property"
        />
      </v-row>
    </v-container>
  </div>
</template>
<script>
import HomeAppBar from "@/components/HomeAppBar.vue";
import DisplayProperty from "@/components/DisplayProperty.vue";
import { mapState, mapActions } from "vuex";
export default {
  name: "property-by-type",
  props: ["id"],
  components: {
    HomeAppBar,
    DisplayProperty,
  },
  computed: {
    ...mapState({
      propertyByType: (state) => state.property.propertyByType.property_by_type,
      propertyByTypeStatus: (state) => state.property.propertyByType.status_code,
    }),
  },
  mounted() {
    this.propertyType();    
  },
  methods: {
    ...mapActions(["showPropertyByType"]),
    propertyType() {
      this.showPropertyByType({
        typeId: this.id,
      });
    },
  },
};
</script>
